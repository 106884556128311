// examtice live
export const global = {
  API_HOST: "https://www.examtice.com/backend/",
  FRONT: "https://www.examtice.com/",
  PAYSTACK_PUBLIC_KEY: "pk_test_d01de450c222c7c7c3c69ab014945ddefcbcddb7",
  GOOGLE_CLIENT_ID:
    "908366023938-m53343se6ginu2kkra15pkh7k4u55vhc.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "700941617941519",
};

// localhost
// export const global = {
//     API_HOST: "http://localhost/exam-frontend/examtice.com-backend/",
//     FRONT: "http://localhost:3000/",
//     PAYSTACK_PUBLIC_KEY: "pk_test_d0a8af076608b3a44e78c929a3ee7e287b51da56",
//        GOOGLE_CLIENT_ID:"",
//  	   FACEBOOK_APP_ID: "",
//   };


